// Imports
// --------------------------------------------
const throttle = require('lodash/throttle');
const platform = require('platform-detect');
const smoothscroll = require('smoothscroll-polyfill');



// Initialise modals
MicroModal.init({
  disableFocus: true,
  onClose: function(modal, element, event) {
      event.preventDefault();
      event.stopPropagation();
      // reset contact form
      $('form').trigger('reset');
      $('form .feedback').text('').removeClass('is-visible');
  }
});


// Update any anchor links to work via javascript so that the smooth scroll polyfill will work
if (!CSS.supports('scroll-behavior', 'smooth')) {
  smoothscroll.polyfill();
  const links = document.querySelectorAll('a[href^="#"]');
  links.forEach(link => {
    const id = link.attributes['href'].value.slice(1);
    const target = document.getElementById(id);
    link.addEventListener('click', () => {
      target.scrollIntoView({ behavior: 'smooth' });
    });
  });
}


// Detect OS for showing relevant appstore button on modal, and adjusting behaviour of floating app button

// OS logic for get app modal buttons
if (platform.ios) {
  $('.button--android').addClass('is-hidden');
} else if (platform.android) {
  $('.button--apple').addClass('is-hidden');
}

// OS logic for floating button
$('.js-app-store-link').on('click', function() {
  if (platform.ios) {
    // Go to itunes app store listing
    window.location.href = 'https://itunes.apple.com/';
  } else if (platform.android) {
    // Go to google play app store listing
    window.location.href = 'https://play.google.com/store';
  } else {
    // open app modal
    MicroModal.show('get-app-modal');
  }
});



// Showing of fixed button based on
// https://stackoverflow.com/questions/123999/how-can-i-tell-if-a-dom-element-is-visible-in-the-current-viewport/7557433#7557433

const heroButton = $('.js-hero-button');

function isElementInViewport (el) {

  // Cope with the element not existing
  if(el.length === 0) {
    return false;
  }

  // Special bonus for those using jQuery
  if (typeof jQuery === "function" && el instanceof jQuery) {
      el = el[0];
  }

  var rect = el.getBoundingClientRect();

  return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
      rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
  );
}


function onVisibilityChange(el, callback) {
  var old_visible;
  return function () {
      var visible = isElementInViewport(el);
      if (visible != old_visible) {
          old_visible = visible;
          if (typeof callback == 'function') {
              callback();
          }
      }
  }
}

function hideGetAppButton() {
  $('.fixed-button').removeClass('is-visible');
}

function showGetAppButton() {
  $('.fixed-button').addClass('is-visible');
}

var floatingButtonVisibilityHandler = onVisibilityChange(heroButton, function() {
  if (isElementInViewport(heroButton)) {
    hideGetAppButton();
  } else {
    showGetAppButton();
  }
});


// Call floatingButtonVisibilityHandler whenever anything that could affect the
// visibility of the button in the hero happens:
$(window).on('DOMContentLoaded load resize', floatingButtonVisibilityHandler);
$('.js-scrollable-content').on('scroll', throttle(onScrollChange, 25));

function onScrollChange() {
  floatingButtonVisibilityHandler();

  if ($(this).scrollTop() > 50) {
    $('.header').addClass('sticky');
  } else {
    $('.header').removeClass('sticky');
  }
}



// Contact Form

$('form').on('submit', function(e) {
  e.preventDefault();
  const feedback = $(this).find('.feedback');
  feedback.text('').removeClass('is-visible');
  $.ajax({
    type: "POST",
    url: 'contact.php',
    dataType: 'json' ,
    data: $(this).serialize(),
    success: (data) => {
      console.log(data);
      $(this).trigger('reset');
      feedback.text('Thanks! Your message has been sent.');
      feedback.addClass('is-visible');
    },
    error: (e) => {
      feedback.text('Sorry, something went wrong. Please try again later.');
      feedback.addClass('is-visible');
    }
  });
})


// FAQ toggle
$('.toggle').on('click', function(e) {
  e.preventDefault();

  var $this = $(this);

  if ($this.next().hasClass('show')) {
      $this.next().find('.toggle').each(function(){
        // slide up all child toggles from selected category
        jQuery(this).next().removeClass('show');
        jQuery(this).next().slideUp(350);
        jQuery(this).removeClass('active');
        jQuery(this).find('> .toggle__icon').removeClass('reverse');
      })
      $this.next().removeClass('show');
      $this.next().slideUp(350);
      $this.removeClass('active');
      $this.find('> .toggle__icon').removeClass('reverse');
  } else {
      $this.next().toggleClass('show');
      $this.next().slideToggle(350);
      $this.addClass('active');
      $this.find('> .toggle__icon').addClass('reverse');
  }

});

// Show/Hide the clear search button
/*
if ($('#searchInput').val() !== '') {
  $('#clearSearch').removeClass('d-none');
} else {
  $('#clearSearch').addClass('d-none');
}

$('#searchInput').on('keyup', function(){
  if ($(this).val() !== '') {
    $('#clearSearch').removeClass('d-none');
  } else {
    $('#clearSearch').addClass('d-none');
  }
})
*/

// Call search function
$('#searchBtn').on('click', function() {
  var search = $('#searchInput').val();
  if (search !== '') {
    window.location.href = '/faq.html?search=' + search;
  } else {
    window.location.href = '/faq.html';
  }
})

$('#searchInput').keypress(function (e) {
  if (e.which == 13) {
    var search = $(this).val();
    if (search !== '') {
      window.location.href = '/faq.html?search=' + search;
    } else {
      window.location.href = '/faq.html';
    }
    return false;    //<---- Add this line
  }
});


// Clear search bar
$('#clearSearch').on('click', function () {
  console.log('clearing search');
  window.location.href = '/faq.html';
});






